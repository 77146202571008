import { useState } from "react";
import Footer from "../Footer/Footer";
import StickyBanner from "../StickyBanner/StickyBanner";
import { Helmet } from "react-helmet";
import MIRRoutes from "../../lib/routes";
import BookNowModal from "../CommonElements/BookNowModal";
import './MicrosoftPurview.scss';
import SectionTabs from "../CloudSolutions/Elements/Sections/SectionTabs/SectionTabs";
import Dynamics from "../CloudSolutions/Elements/Sections/Dynamics";
import HowHelpSection from "../CloudSolutions/Elements/Sections/HowHelpSection";
import StepsSlider from "../CloudSolutions/Elements/StepProcess/StepProcess";
import SuccessStoriesDynamic from "../CommonElements/SuccessStoriesDynamic";
import ContactSection from "../CloudSolutions/Elements/Sections/ContactSection";

export default function MicrosoftPurview() {
    const [tabs, setTabs] = useState([
        { name: 'Overview', id: 'section-1' },
        { name: 'Introduction', id: 'section-2' },
        { name: 'Benefits', id: 'section-3' },
        { name: 'How we can help', id: 'section-4' },
        { name: 'Case studies', id: 'section-5' },
        { name: 'Contact Us', id: 'section-6' },
    ]);
    const successStoriesContent = [

        {
            image: "/success-stories-custom/6.png",
            timeToRead: "10",
            desc: "6Harmonics Drives Agility at Workplace with Cloud and Office 365 Migration",
            linkToRead: "/about-us/case-studies/6-harmonics-agility-microsoft-365"
        },
        {
            image: "/success-stories-custom/13.png",
            timeToRead: "5",
            desc: "MIR Successfully Improves AGMD Group Inc\'s Productivity and Security with Microsoft 365 License Migration",
            linkToRead: "/about-us/case-studies/agmd"
        },
        {
            image: "/success-stories-custom/3.png",
            timeToRead: "3",
            desc: "Make A Wish Upscales Organization Operations & Reduce Ongoing Costs with Azure Public Migration",
            linkToRead: "/about-us/case-studies/make-a-wish-canada-azure-migration"
        }
    ];
    const [showRegionPopup, setShowRegionPopup] = useState(false);
    return (
        <main className="microsoft-purview">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Microsoft Purview - Business Services | MIR Digital Solutions</title>
                <meta name="description" content="Empowering Data Governance, Security, and Discovery in Complex Ecosystems" />
                <link rel="canonical" href={`${window.location.origin}${MIRRoutes.MicrosoftPurview}`} />
            </Helmet>
            <StickyBanner />
            <BookNowModal showRegionPopup={showRegionPopup} setShowRegionPopup={setShowRegionPopup} />
            <section className='tw-pt-[120px] tw-pb-[160px] tw-bg-cover tw-bg-center tw-bg-no-repeat' style={{ backgroundImage: 'url(/supply-chain/bg.svg)' }}>
                <div className="container mx-auto text-center">
                    <img src="/purview/purview-logo.png" className='sec1-purview-logo tw-mb-[30px]' alt="MIR Digital Solutions is a Microsoft Solutions Partner for digital, app innovation, and Azure services." />
                    <h4 className='tw-mb-[24px] mir-h6-medium mir-text-primary-500'>Empowering Data Governance, Security, <br />and Discovery in Complex Ecosystems </h4>
                    <h2 className='mir-display-semibold-sm mir-text-neutral-800 m-0 text-capitalize'>Microsoft Purview</h2>
                </div>
            </section>

            <SectionTabs tabs={tabs} />

            <section className='tw-py-[96px] tw-relative'>
                <span data-section id="section-1" className='tw-absolute tw-top-[-100px]'></span>
                <div className="container mx-auto">
                    <div className="text-center">
                        <h4 className='tw-uppercase mir-text-primary-500 mir-p-overline-lg tw-mb-[16px]'>Overview</h4>
                        <h2 className='mir-h1-semibold tw-mb-[24px] tw-max-w-full mx-auto md:tw-max-w-[590px] mir-text-neutral-800'>Streamline your business operations with Microsoft Purview</h2>
                        <p className='mir-p-md-regular mir-text-neutral-800 tw-max-w-full md:tw-max-w-[862px] mx-auto tw-mb-[64px]'>In today’s world, Microsoft Purview plays a critical role in helping organizations manage, govern, and protect their data across complex and diverse ecosystems.
                            With the exponential growth of data, businesses face challenges in ensuring compliance, maintaining data security, and enabling efficient data discovery.
                        </p>
                        <div className='tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-x-[32px] tw-gap-y-[48px]'>
                            {
                                [
                                    { heading: "Data Discovery and Management", desc: "Struggling with fragmented data across silos, incomplete metadata, and the rapid growth of ungoverned data was a challenge." },
                                    { heading: "Regulatory Compliance", desc: "Navigation of complex global regulations, cross-border data handling, and maintaining audit readiness is challenging." },
                                    { heading: "Integration Issues", desc: "Difficulty integrating Purview with legacy systems, heterogeneous environments, or unsupported niche applications." },
                                    { heading: "Resource and Expertise Constraints", desc: "Limited skilled personnel, high implementation costs, and time-consuming configurations for large-scale deployments." },
                                    { heading: "Security and Access Control Risks", desc: "Challenges in setting up proper role-based access, addressing shadow IT, and accurately identifying sensitive data." },
                                    { heading: "Change Management and Scalability", desc: "Resistance to adopting governance practices, enforcing policies consistently, and scaling effectively for dynamic environments." }
                                ].map((item, index) => {
                                    return <div key={`challenge-item-${index}`} className='text-start'>
                                        <img src="/365/sad.svg" alt="sad-icon" className='tw-mb-[16px]' />
                                        <h5 className='mir-p-md-semibold mir-text-neutral-800'>{item.heading}</h5>
                                        <p className='tw-max-w-full md:tw-max-w-[384px] mir-p-rg-regular mir-text-neutral-700'>{item.desc}</p>
                                    </div>
                                })
                            }
                        </div>
                    </div>

                </div>
            </section>

            <div className='tw-relative'>
                <span className='tw-absolute tw-top-[-100px]' data-section id={'section-2'}></span>
                <Dynamics
                    icon={'/purview/purview-logo.png'}
                    overline={'Introduction'}
                    heading={<>What is Microsoft Purview? </>}
                    desc={<>Microsoft Purview is a unified data governance and compliance solution designed to help organizations manage and protect their data across various environments. It provides tools for discovering, understanding, and governing data to ensure compliance and enable data-driven decision-making.</>}
                    videoPlaceholder={"/supply-chain/video-placeholder.png"}
                    iframe={<iframe width="100%" height="541px" src="https://www.youtube.com/embed/6--R2mtXqwE?si=qfl9w4CTeVf2sdNE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>}
                />
            </div>
            <div className='mir-bg-primary-500 tw-relative tw-overflow-hidden'>
                <img src="/supply-chain/light-blue-banner-bg.png" alt="blue-banner-bg" className='tw-w-full tw-h-full tw-absolute tw-z-0 tw-inset-0' />
                <div className="container mx-auto">
                    <div className={`tw-px-[36px] tw-py-[36px] md:tw-px-[64px] md:tw-py-[72px]`}>
                        <div className='tw-relative tw-z-10 tw-flex tw-justify-between tw-items-center tw-flex-wrap'>
                            <h2 className='tw-max-w-full tw-mb-[36px] md:tw-mb-[0px] md:tw-max-w-[672px] mir-h5-semibold text-white'>Discover how Microsoft Purview can benefit your business</h2>
                            <button onClick={() => { setShowRegionPopup(true) }} className='mir-btn-secondary mir-btn-md mir-text-primary-500 mir-bg-white'>
                                <svg className='tw-mr-[12px]' width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.3727 12.8798L13.6922 11.2306L13.682 11.2259C13.491 11.1441 13.2825 11.1114 13.0756 11.1304C12.8687 11.1495 12.6698 11.2199 12.4969 11.3352C12.4765 11.3487 12.457 11.3633 12.4383 11.379L10.5367 13.0001C9.33204 12.4149 8.08829 11.1806 7.50313 9.99149L9.12657 8.06102C9.14219 8.04149 9.15704 8.02196 9.1711 8.00087C9.28394 7.82845 9.35239 7.63081 9.37039 7.42553C9.38838 7.22026 9.35534 7.01372 9.27423 6.8243V6.81493L7.62032 3.12821C7.51309 2.88076 7.3287 2.67463 7.09468 2.54058C6.86067 2.40654 6.58958 2.35177 6.32188 2.38446C5.26326 2.52376 4.29155 3.04366 3.58824 3.84704C2.88492 4.65042 2.49809 5.68234 2.50001 6.75009C2.50001 12.9532 7.54688 18.0001 13.75 18.0001C14.8177 18.002 15.8497 17.6152 16.6531 16.9119C17.4564 16.2085 17.9763 15.2368 18.1156 14.1782C18.1484 13.9106 18.0937 13.6396 17.9598 13.4056C17.8259 13.1716 17.62 12.9871 17.3727 12.8798ZM13.75 16.7501C11.0987 16.7472 8.55687 15.6927 6.68214 13.818C4.8074 11.9432 3.7529 9.40136 3.75001 6.75009C3.74707 5.98719 4.02192 5.2493 4.52324 4.67423C5.02456 4.09916 5.71806 3.72623 6.47423 3.62509C6.47392 3.6282 6.47392 3.63134 6.47423 3.63446L8.11485 7.30634L6.50001 9.23915C6.48362 9.25801 6.46873 9.27812 6.45548 9.2993C6.33791 9.47971 6.26894 9.68743 6.25525 9.90232C6.24157 10.1172 6.28362 10.332 6.37735 10.5259C7.08516 11.9735 8.54376 13.4212 10.007 14.1282C10.2023 14.2211 10.4184 14.2617 10.634 14.2461C10.8497 14.2304 11.0576 14.1592 11.2375 14.0391C11.2576 14.0256 11.2769 14.011 11.2953 13.9954L13.1945 12.3751L16.8664 14.0196C16.8664 14.0196 16.8727 14.0196 16.875 14.0196C16.7751 14.7769 16.4027 15.4718 15.8275 15.9743C15.2524 16.4769 14.5138 16.7527 13.75 16.7501Z" fill="#1D68F0" />
                                </svg>
                                <span className='tw-mr-[12px] mir-p-rg-medium'>Contact our expert</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Key Features */}
            <section className='tw-relative mir-bg-neutral-800 tw-bg-bottom tw-bg-cover tw-bg-no-repeat' style={{ backgroundImage: 'url(/business/service-bundles-bg.png)' }}>
                <span data-section id="section-3-t" className='tw-absolute tw-top-[-100px]'></span>
                <div className='container tw-mx-auto tw-text-center tw-pt-[120px] tw-pb-[120px]'>
                    <h4 className='mir-p-overline-lg text-primary-500 tw-mb-[24px]'>FEATURES</h4>
                    <h2 className='mir-h1-semibold mir-text-neutral-100 tw-mb-[36px] tw-max-w-[100%] md:tw-max-w-[534px] tw-mx-auto'>Key features of Microsoft Purview</h2>
                    <h3 className='mir-text-neutral-100 mir-p-md-regular tw-max-w-[100%] md:tw-max-w-[734px] tw-mx-auto'>Microsoft Purview provides a robust solution for data governance, security, and compliance across diverse data environments. Key features of Microsoft Purview includes</h3>
                </div>
            </section>
            <section className='tw-py-[96px] mir-bg-neutral-100'>
                <div className="container mx-auto">
                    <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-[52px]">
                        {
                            [
                                { heading: 'Unified Data Governance', desc: 'Centralized platform to govern data across on-premises, cloud, and SaaS environments.' },
                                { heading: 'Data Discovery and Classification', desc: 'Automated scanning and classification of sensitive and diverse data sources.' },
                                { heading: 'Data Catalog and Lineage', desc: 'Searchable catalog with metadata and lineage tracking to understand data origins and transformations.' },
                                { heading: 'Policy and Compliance Management', desc: 'Enforces governance policies and ensures regulatory compliance with insights and reports.' },
                                { heading: 'Seamless Integration', desc: "Connects with Azure services, Power BI, and 200+ data sources for streamlined operations." },
                                { heading: 'Collaboration and Insights', desc: 'Enables teamwork with shared tools and provides dashboards for data usage and governance metrics.' }
                            ].map((item, index) => {
                                return <div key={`benefit-feature-index-${index}`}>
                                    <h4 className='mir-text-primary-500 mir-h6-semibold tw-mb-[12px]'>{item.heading}</h4>
                                    <h5 className='mir-text-neutral-600 mir-p-rg-regular'>{item.desc}</h5>
                                </div>
                            })
                        }
                    </div>
                </div>
            </section>

            <section className='tw-pb-[64px] mir-bg-neutral-100'>
                <div className="container mx-auto tw-bg-[#001d6c] tw-relative md:tw-rounded-[12px]">
                    <img src="/supply-chain/blue-banner-bg.png" className='tw-absolute tw-z-0 tw-inset-0 tw-w-full tw-h-full tw-rounded-[12px]'
                        alt="Streamline data analysis and reporting with Microsoft Power BI" />
                    <div className="tw-py-[52px] tw-px-[30px] md:tw-py-[52px] md:tw-px-[64px] tw-rounded-[12px] tw-flex tw-items-center tw-flex-wrap tw-justify-between tw-relative tw-z-10">
                        <h3 className='tw-mb-[24px] md:tw-mb-0 tw-max-w-full md:tw-max-w-[527px] mir-h5-semibold mir-text-neutral-100'>Microsoft Purview is the go-to choice for data governance, security and privacy</h3>
                        <button className='mir-btn-md mir-btn-primary mir-p-rg-medium' onClick={() => { setShowRegionPopup(true) }}>
                            <span className='tw-mr-[12px]'>Make it yours today</span>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.3172 10.4422L11.6922 16.0672C11.5749 16.1845 11.4159 16.2504 11.25 16.2504C11.0841 16.2504 10.9251 16.1845 10.8078 16.0672C10.6905 15.9499 10.6247 15.7909 10.6247 15.625C10.6247 15.4592 10.6905 15.3001 10.8078 15.1828L15.3664 10.625H3.125C2.95924 10.625 2.80027 10.5592 2.68306 10.442C2.56585 10.3247 2.5 10.1658 2.5 10C2.5 9.83425 2.56585 9.67528 2.68306 9.55807C2.80027 9.44086 2.95924 9.37501 3.125 9.37501H15.3664L10.8078 4.8172C10.6905 4.69992 10.6247 4.54086 10.6247 4.37501C10.6247 4.20916 10.6905 4.0501 10.8078 3.93282C10.9251 3.81555 11.0841 3.74966 11.25 3.74966C11.4159 3.74966 11.5749 3.81555 11.6922 3.93282L17.3172 9.55782C17.3753 9.61587 17.4214 9.6848 17.4529 9.76067C17.4843 9.83655 17.5005 9.91788 17.5005 10C17.5005 10.0821 17.4843 10.1635 17.4529 10.2393C17.4214 10.3152 17.3753 10.3842 17.3172 10.4422Z" fill="white" />
                            </svg>
                        </button>
                    </div>
                </div>
            </section>

            <section className='tw-py-[96px] mir-bg-neutral-100' data-section id='section-3'>
                <div className="container mx-auto">
                    <div className='text-center'>
                        <h4 className='mx-auto mir-p-overline-lg mir-text-primary-500 tw-text-uppercase tw-mb-[16px]'>BENEFITS</h4>
                        <h2 className='mx-auto mir-h1-semibold mir-text-neutral-800 tw-max-w-full md:tw-max-w-[448px] tw-mb-[80px]'>Why should a business choose Microsoft 365?</h2>
                    </div>
                    <div className='tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-x-[32px] tw-gap-y-[48px]'>
                        {
                            [
                                { icon: "/purview/benefits/1.png", heading: "Comprehensive Data Governance", desc: "Offers comprehensive data governance to help organizations manage and protect their data efficiently" },
                                { icon: "/purview/benefits/2.png", heading: "Automated Compliance", desc: "Ensures automated compliance, enabling organizations to meet regulatory requirements with ease" },
                                { icon: "/purview/benefits/3.png", heading: "Enhanced Data Discovery", desc: "Enhances data discovery, allowing organizations to locate and understand their data assets quickly" },
                                { icon: "/purview/benefits/4.png", heading: "Data Security and Privacy", desc: "Strengthens data security and privacy, safeguarding sensitive information across the organization" },
                                { icon: "/purview/benefits/5.png", heading: "Seamless Integration", desc: "Offers seamless integration with various data sources and services, enhancing data governance across platforms" },
                                { icon: "/purview/benefits/6.png", heading: "Improved Collaboration & Actionable Insights", desc: "Enhances collaboration and provides actionable insights, empowering teams to work together effectively and make informed decisions" }
                            ].map((item, index) => {
                                return <div key={`365-benefits-item-${index}`}>
                                    <img src={item.icon} className='purview-benefit-icon tw-mb-[24px]' alt="benefit-icon" />
                                    <h5 className='mir-h6-semibold mir-text-neutral-800 tw-mb-[12px]'>{item.heading}</h5>
                                    <p className='mir-text-neutral-600 mir-p-rg-regular'>{item.desc}</p>
                                </div>
                            })
                        }
                    </div>
                </div>
            </section>

            <div className='tw-relative' style={{ marginTop: '-24px' }}>
                <span data-section id="section-4" className='tw-absolute tw-top-[-100px]'></span>
                <HowHelpSection
                    overline={'HOW WE HELP YOU'}
                    heading={<>Why should you choose <span style={{ color: '#1D68F0' }}>MIR Digital Solutions</span> to fuel your business growth through Microsoft Purview?</>}
                    desc={<>Fuel your business growth with MIR Digital Solutions and Microsoft Purview! Our experts ensure seamless integration, secure your sensitive data, and simplify compliance, empowering you to unlock insights and make smarter decisions. From efficient data discovery to tailored governance solutions, we help you harness Purview’s full potential for streamlined operations and lasting success. Let us drive your data transformation—because your business deserves the best.</>}
                    image={'url(/purview/help-bg.png) no-repeat'}
                />
            </div>
            <div className="delivering-wrapper py-5 mb-1">
                <div className="container">
                    <h3 className='mir-h3-semibold mb-4'>Our Microsoft Purview Services</h3>
                </div>
            </div>
            <section>
                <div className="container mx-auto">
                    <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-4 tw-gap-[32px] tw-pt-[36px] tw-pb-[64px]" style={{ borderTop: '1px solid var(--neutral-200, #EEEEF2)' }}>
                        <div className='tw-col-span-2 tw-flex tw-items-start tw-justify-start'>
                            <h6 className='tw-mr-[16px] mir-p-rg-medium mir-text-primary-500'>01</h6>
                            <h5 className='mir-h5-semibold mir-text-neutral-800'>Data Governance Implementation </h5>
                        </div>
                        <div className='tw-col-span-2'>
                            <p className='mir-p-rg-regular mir-text-neutral-700 tw-max-w-full md:tw-max-w-[640px]'>
                                We help you establish a strong foundation for data governance by:
                            </p>
                            <ul>
                                <li>Mapping and classifying your data for easier discovery. </li>
                                <li>Building and applying consistent taxonomy and metadata models. </li>
                                <li>Setting up data lineage tracking to monitor and visualize data flows.</li>
                            </ul>
                            <p className='mir-p-rg-regular mir-text-neutral-700 tw-max-w-full md:tw-max-w-[640px]'>
                                With MIR, you can trust that your data governance framework will be aligned with your business goals and scalable for future growth.
                            </p>
                        </div>
                    </div>
                    <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-4 tw-gap-[32px] tw-pt-[36px] tw-pb-[64px]" style={{ borderTop: '1px solid var(--neutral-200, #EEEEF2)' }}>
                        <div className='tw-col-span-2 tw-flex tw-items-start tw-justify-start'>
                            <h6 className='tw-mr-[16px] mir-p-rg-medium mir-text-primary-500'>02</h6>
                            <h5 className='mir-h5-semibold mir-text-neutral-800'>Compliance and Risk Management</h5>
                        </div>
                        <div className='tw-col-span-2'>
                            <p className='mir-p-rg-regular mir-text-neutral-700 tw-max-w-full md:tw-max-w-[640px]'>
                                Stay ahead of evolving regulations and minimize risks with MIR’s compliance services:
                            </p>
                            <ul>
                                <li>Configure Microsoft Purview to meet industry-specific regulatory requirements like GDPR, HIPAA, or CCPA.</li>
                                <li>Perform detailed risk assessments to identify compliance gaps. </li>
                                <li>Set up automated monitoring and reporting for continuous compliance assurance.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-4 tw-gap-[32px] tw-pt-[36px] tw-pb-[64px]" style={{ borderTop: '1px solid var(--neutral-200, #EEEEF2)' }}>
                        <div className='tw-col-span-2 tw-flex tw-items-start tw-justify-start'>
                            <h6 className='tw-mr-[16px] mir-p-rg-medium mir-text-primary-500'>03</h6>
                            <h5 className='mir-h5-semibold mir-text-neutral-800'>Data Security and Protection</h5>
                        </div>
                        <div className='tw-col-span-2'>
                            <p className='mir-p-rg-regular mir-text-neutral-700 tw-max-w-full md:tw-max-w-[640px]'>
                                Protect your most valuable asset—your data—with our security-focused services:
                            </p>
                            <ul>
                                <li>Implement Purview’s sensitivity labels and encryption to safeguard sensitive information. </li>
                                <li>Design and enforce access control policies tailored to your organizational structure.</li>
                                <li>Deploy Data Loss Prevention (DLP) policies to reduce risks of accidental or malicious data leaks.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-4 tw-gap-[32px] tw-pt-[36px] tw-pb-[64px]" style={{ borderTop: '1px solid var(--neutral-200, #EEEEF2)' }}>
                        <div className='tw-col-span-2 tw-flex tw-items-start tw-justify-start'>
                            <h6 className='tw-mr-[16px] mir-p-rg-medium mir-text-primary-500'>04</h6>
                            <h5 className='mir-h5-semibold mir-text-neutral-800'>Data Discovery and Analytics</h5>
                        </div>
                        <div className='tw-col-span-2'>
                            <p className='mir-p-rg-regular mir-text-neutral-700 tw-max-w-full md:tw-max-w-[640px]'>
                                Unlock the full potential of your data with enhanced discovery and insights:
                            </p>
                            <ul>
                                <li>Establish a unified data catalog for streamlined access to enterprise data.</li>
                                <li>Customize search and discovery tools to empower teams with actionable insights. </li>
                                <li>Leverage Purview’s analytics to uncover trends and drive informed decision-making. </li>
                            </ul>
                        </div>
                    </div>
                    <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-4 tw-gap-[32px] tw-pt-[36px] tw-pb-[64px]" style={{ borderTop: '1px solid var(--neutral-200, #EEEEF2)' }}>
                        <div className='tw-col-span-2 tw-flex tw-items-start tw-justify-start'>
                            <h6 className='tw-mr-[16px] mir-p-rg-medium mir-text-primary-500'>05</h6>
                            <h5 className='mir-h5-semibold mir-text-neutral-800'>Migration and Integration </h5>
                        </div>
                        <div className='tw-col-span-2'>
                            <p className='mir-p-rg-regular mir-text-neutral-700 tw-max-w-full md:tw-max-w-[640px]'>
                                Simplify your transition to a modern data governance framework:
                            </p>
                            <ul>
                                <li>Seamlessly integrate Purview with your existing systems, including Microsoft 365. </li>
                                <li>Support intelligent content migration using THEMIS ICE to maintain compliance and structure during transitions. </li>
                                <li>Ensure a smooth, low-disruption migration process backed by our team of experts. </li>
                            </ul>
                        </div>
                    </div>
                    <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-4 tw-gap-[32px] tw-pt-[36px] tw-pb-[64px]" style={{ borderTop: '1px solid var(--neutral-200, #EEEEF2)' }}>
                        <div className='tw-col-span-2 tw-flex tw-items-start tw-justify-start'>
                            <h6 className='tw-mr-[16px] mir-p-rg-medium mir-text-primary-500'>06</h6>
                            <h5 className='mir-h5-semibold mir-text-neutral-800'>Training and Advisory Services</h5>
                        </div>
                        <div className='tw-col-span-2'>
                            <p className='mir-p-rg-regular mir-text-neutral-700 tw-max-w-full md:tw-max-w-[640px]'>
                                Empower your team to make the most of Purview with MIR’s guidance:
                            </p>
                            <ul>
                                <li>Deliver hands-on training sessions to build confidence in using Purview tools. </li>
                                <li>Provide advisory services to develop long-term governance strategies tailored to your goals. </li>
                                <li>Setting up data lineage tracking to monitor and visualize data flows.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <div className="delivering-wrapper py-5 mb-5">
                <div className="container">
                    <h3 className='mir-h5-semibold mb-4'>As a Microsoft Partner, we deliver</h3>
                    <div className="row">
                        {
                            [
                                'Comprehensive data governance solutions that provide end-to-end visibility and control over your data estate, ensuring compliance and data quality. ',
                                "Integration with Microsoft Purview's unified data governance platform, enabling seamless data discovery, classification, and lineage tracking across on-premises, multi-cloud, and SaaS environments.",
                                "Expertise in implementing Microsoft Purview's data security and compliance features, including data loss prevention, information protection, and risk management, to safeguard your sensitive information. ",
                                "Support for leveraging Microsoft Purview's AI-powered data insights, facilitating informed decision-making and enhanced operational efficiency.",
                                "Accelerated application development and flexibility with the Power Platform, enabling rapid creation of custom solutions that integrate seamlessly with Microsoft Purview's data governance capabilities.",
                                "Deep expertise and a rich history with CRM, ERP, HCM, collaboration, productivity, and project management applications, ensuring comprehensive solutions tailored to your business needs. "
                            ].map((item) => {
                                return <div className="col-12 col-md-4">
                                    <div className="deliver-item d-flex align-items-start justify-content-start">
                                        <img src="/project-operations/blue-check.svg" className='mt-3 me-3' alt="delivering-icon" />
                                        <h3 className='mir-p-rg-regular mt-3' style={{ maxWidth: '450px' }}>{item}</h3>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>
            <StepsSlider
                isLarge={true}
                subtitle={"Our specialist team offers custom-tailored implementation of Microsoft Purview, enabling organizations to achieve comprehensive data governance and compliance across their data estate. By leveraging Microsoft Purview's extensibility features, we adapt and integrate the platform to meet your specific business needs, ensuring seamless data discovery, classification, and policy enforcement."}
            />
            <div className='tw-relative'>
                <span data-section id="section-5" className='tw-absolute tw-top-[-100px]'></span>
                <SuccessStoriesDynamic
                    successStoriesContent={successStoriesContent}
                />
            </div>
            <div className='tw-relative'>
                <span data-section id="section-6" className='tw-absolute tw-top-[-100px]'></span>
                <ContactSection />
            </div>
            <Footer />
        </main>
    );
}